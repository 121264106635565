import axios from 'axios'


import { url } from './constants'

const currencyUrl = url + 'api/currencies/'

const getCurrencyChangeTaxe = async (currencyFrom, currencyTo) => {
  let response = {}
  await axios
    .get(
      currencyUrl + 'rate/from/' + currencyFrom + '/to/' + currencyTo,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = 1
    })
  return response
}

const getAllCurrenciesName = async () => {
  let response = {}
  await axios
    .get(
      currencyUrl + 'all/name',
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = 1
    })
  return response
}

const getAllCurrencies = async () => {
  let response = {}
  await axios
    .get(
      currencyUrl + 'all',
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = 1
    })
  return response
}

const getCurrenciesByZone = async (zone) => {
  let response = {}
  await axios
    .post(
      currencyUrl + 'retrieve-currency',
      zone,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}

export default {
  getCurrencyChangeTaxe,
  getAllCurrenciesName,
  getAllCurrencies,
  getCurrenciesByZone
}
