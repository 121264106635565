import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    styled,
    TextField,
    Grid
} from '@mui/material'
import userApiService from '../../services/userApiService';
import { useTranslation } from 'react-i18next';
import TransitionAlerts from './transitionAlert';
import StorageService from '../../services/StorageService';

const OtpVerificationModal = ({ onSubmit, open, visualisationToastTime, onClose,
    showSuccessMessage,
    showErrorMessage,
}) => {
    const [loader, setLoader] = useState(false)
    const { t } = useTranslation();
    const [codeOTPVisibility, setCodeOTPVisibility] = useState(false)
    let timer = 0
    let secondss = 600
    const [displayOtpCodeError, setDisplayOtpCodeError] = useState(false)
    const [visualisationToast, setVisualisationToast] = useState(visualisationToastTime ?? 3000)
    const [time, setTime] = useState({})
    const [seconds, setSeconds] = useState(600)

    const otpClick = useCallback(() => {
        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag('event','otp_verification_account', {
                'event_category': 'Button',
                'event_label': 'OTP Verification Button Click'
            });
        }
    }, []);
    
    const handleFormSubmit = async () => {
        // event?.preventDefault();
        // Ton code de soumission de formulaire ici
        let user = StorageService.read('user');
        setLoader(true);
        try {
            // Votre logique de soumission
            if (user?.phone) {
                await handleSubmitData(user?.phone, values.otpCode);
            } else {
                await handleSubmitData(user?.email, values.otpCode);
            }
        } catch (error) {
            console.error(t("errors.loginError"), error);
        }
        setLoader(false);
    }

    const formSchema = yup.object().shape({
        otpCode: yup.string().required(t('components.auth.validate_otp.otpCode')),
    })


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            onSubmit: handleFormSubmit,
            validationSchema: formSchema
        })

    const secondsToTime = secs => {
        let hours = Math.floor(secs / (60 * 60))

        let divisor_for_minutes = secs % (60 * 60)
        let minutes = Math.floor(divisor_for_minutes / 60)

        let divisor_for_seconds = divisor_for_minutes % 60
        let seconds = Math.ceil(divisor_for_seconds)

        let obj = {
            h: hours,
            m: minutes,
            s: seconds
        }
        return obj
    }
    function startTimer() {
        if (timer === 0 && secondss > 0) {
            timer = setInterval(() => {
                countDown()
            }, 1000)
        }
    }
    function countDown() {
        // Remove one second, set state so a re-render happens.
        secondss -= 1
        setTime(secondsToTime(secondss))

        // Check if we're at zero.
        if (secondss === 0) {
            clearInterval(timer)
        }
    }

    const handleSubmitData = async (username, otpCode) => {
        otpClick();
        let response = await userApiService.checkUserCode(
            {
                username, otpCode
            }
        );

        if (response?.error) {
            showErrorMessage({
                title: t("errors.loginFailed"), message: response.error
            });
        } else {
            let user = await userApiService.getUserById(response?.id);
            if (user?.error) {
                showErrorMessage({
                    title: t("errors.userRecoveryFailed"), message: response.error
                });
            } else {
                StorageService.write("user",user);
                showSuccessMessage({
                    title: t("login.succeed.title"), message: t("login.succeed.message")
                });
                onSubmit(user)
            }
        }
    }

    useEffect(() => {
        let timeLeftVar = secondsToTime(seconds)
        setTime(timeLeftVar)
    }, [codeOTPVisibility])

    useEffect(() => {
        startTimer();
    }, [])


    return (
        <>
            <Dialog
                open={open}
                maxWidth={false}
                sx={{
                    zIndex: 100
                }}
            >
                <DialogContent
                    sx={{
                        maxWidth: 555,
                        width: '100%'
                    }}
                >
                    <ContentWrapper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h3 className='text-center' mb={1}>
                                    {t('components.auth.validate_otp.title')}
                                </h3>
                                <div className='text-center'>
                                    <small className='m-2 text-center font-semibold'>
                                        {t('components.auth.validate_otp.message')}
                                    </small>
                                </div>
                                <div className='text-center'>
                                    <small className='m-2 text-center text-primary font-semibold'>
                                        {t('components.auth.validate_otp.expiring_link')}{' '}
                                        <span style={{ color: 'red' }}>{'  ' + time.m + ' : ' + time.s}</span>
                                    </small>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    mb={1.5}
                                    fullWidth
                                    name='otpCode'
                                    size='medium'
                                    type='number'
                                    variant='outlined'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={t('components.auth.validate_otp.received_code_label')}
                                    placeholder={t('components.auth.validate_otp.received_code_text')}
                                    error={!!touched.otpCode && !!errors.otpCode}
                                    helperText={touched.otpCode && errors.otpCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button aria-label="Name"
                                    fullWidth
                                    onClick={() => handleFormSubmit()}
                                    color='primary'
                                    variant='contained'
                                    sx={{
                                        height: 44
                                    }}
                                >
                                    <span style={{ marginRight: '5px' }}>{t('global.validated')}</span>

                                    {loader ? <i className="pi pi-spin pi-spinner text-900" style={{ fontSize: '2rem' }}></i> : <></>}
                                </Button>
                            </Grid>

                            {displayOtpCodeError && (
                                <TransitionAlerts
                                    severity={'error'}
                                    title={''}
                                    message={
                                        t('components.auth.validate_otp.wrongCode_label')
                                    }
                                />
                            )}
                        </Grid>
                    </ContentWrapper>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 3,
                            right: 3,
                        }}
                        onClick={onClose}
                    >
                        <i className='pi  pi-times'></i>
                    </IconButton>
                </DialogContent>
            </Dialog >
        </>
    )
};

export default OtpVerificationModal;

const initialValues = {
    otpCode: "",
};


const ContentWrapper = styled(Box)(({ theme }) => ({
    '& .carousel:hover': {
        cursor: 'pointer',
        '& .carousel__back-button': {
            opacity: 1,
            left: 10
        },
        '& .carousel__next-button': {
            opacity: 1,
            right: 10
        }
    },
    '& .carousel__next-button, & .carousel__back-button': {
        opacity: 0,
        boxShadow: 'none',
        transition: 'all 0.3s',
        background: 'transparent',
        color: theme.palette.primary.main,
        ':disabled': {
            color: theme.palette.grey[500]
        },
        ':hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    '& .carousel__back-button': {
        left: 0
    },
    '& .carousel__next-button': {
        right: 0
    }
})) // =====================================================
