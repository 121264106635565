import React, { useCallback, useRef, useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import EyeToggleButton from "../EyeToggleButton";
import authApiService from "../../services/authApiService";
import { makeid, url } from '../../services/constants'
import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    styled,
    Table,
    TableContainer,
    TextField,
    Tooltip
} from '@mui/material'
import userApiService from '../../services/userApiService';
import { useTranslation, Trans } from 'react-i18next';
import useWindowSize from '../layout/WindowResize';
import Cookies from 'js-cookie';
import ResetPasswordComponent from './ResetPasword';
import StorageService from '../../services/StorageService';

const LoginModal = ({ onSubmit, open, visualisationToastTime, onClose, openCreateModal,
    showSuccessMessage,
    showErrorMessage,
    showOtpVerification
}) => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [passwordVisibility, setPasswordVisibility] = useState(false)
    const [loader, setLoader] = useState(false)
    const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false)
    const { t } = useTranslation();
    const [width, height] = useWindowSize();
    const [visualisationToast, setVisualisationToast] = useState(visualisationToastTime ?? 3000)


    const loginClick = useCallback(() => {
        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag('event', 'login_to_account', {
                'event_category': 'Button',
                'event_label': 'Login Button Click'
            }); 
        }
    }, []);

    const handleLoginFormSubmit = async (event) => {
        loginClick();
        Cookies.remove('accessToken');

        // event?.preventDefault();
        // Ton code de soumission de formulaire ici
        setLoader(true);
        try {
            // Votre logique de soumission
            let response = await authApiService.login(
                {
                    username, password
                }
            );

            if (response?.error) {
                showErrorMessage({
                    title: t("errors.loginFailed"), message: response.error
                });
            } else {
                StorageService.write("user",response);
                console.log('user: ', response);

                if (response?.enabled) {
                    showSuccessMessage({
                        title: t("login.succeed.title"), message: t("login.succeed.message")
                    });
                    onSubmit(response)
                    onClose();
                } else {
                    showSuccessMessage({
                        title: t("login.account_not_enable.title"), message: t("login.succeed.message")
                    });
                    showOtpVerification(response);
                }
            }
        } catch (error) {
            console.error(t("errors.loginError"), error);
        }
        setLoader(false);
    };

    const togglePasswordVisibility = useCallback(() => {
        setPasswordVisibility(visible => !visible)
    }, [])

    return (
        <>
            <Dialog
                open={open}
                maxWidth={false}
                sx={{
                    zIndex: 100
                }}
            >
                <DialogContent
                    sx={{
                        maxWidth: 555,
                        width: '100%'
                    }}
                >
                    <ContentWrapper>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={loginSchema}
                            onSubmit={handleLoginFormSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <form className="px-4 md:px-6 py-2" onSubmit={handleSubmit}>
                                    {/* <p className="m-0 font-bold text-lg text-center text-gray-900">
                                        {t("components.auth.facebookSignUp")}
                                    </p>
                                    <div className='m-2' style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center'
                                    }}>
                                        <a href={url + "oauth2/authorization/facebook"} className="font-bold flex no-underline text-center align-items-center text-primary"
                                        >
                                            <img src="/assets/icons/icons8-facebook-48.png" alt="Facebook Logo" style={{
                                                width: '1.5rem',
                                            }} />
                                            Facebook
                                        </a>
                                        {t("global.or")}
                                        <a className="font-bold no-underline align-items-center flex text-primary" href={url + "oauth2/authorization/google"}>
                                            <img src="/assets/icons/icons8-google-48.png" alt="Google Logo" style={{
                                                width: '1.5rem',
                                            }} /> Google
                                        </a>
                                    </div> */}

                                    {/* <p className="m-0 font-bold text-lg text-center text-gray-900">
                                        <a href={url + "oauth2/authorization/facebook"} className="font-bold no-underline text-center text-primary">Inscrivez-vous avec Facebook</a> ou <a className="font-bold no-underline text-primary" href={url + "oauth2/authorization/google"}>Google</a>
                                    </p> */}
                                    {/* <div className="flex w-full align-items-center justify-content-center gap-2">
                                        <div className="bg-gray-900" style={{
                                            width: '-webkit-fill-available',
                                            height: '1px'
                                        }}></div>
                                        ou
                                        <div className="bg-gray-900" style={{
                                            width: '-webkit-fill-available',
                                            height: '1px'
                                        }}></div>
                                    </div> */}
                                    <div className='h-full w-full '>
                                        <h1 className='text-center'>
                                            {t("login.modalTitle")}
                                        </h1>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12 mt-3">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                id='username'
                                                name='username'
                                                label={t("components.auth.emailOrPhone")}
                                                type='text'
                                                value={values.username}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setUsername(e.target.value)
                                                }}
                                                onBlur={handleBlur}
                                                error={!!touched.username && !!errors.username}
                                                helperText={touched.username && errors.username}
                                            />
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12 flex align-items-center justify-content-end primary">
                                            <small onClick={() => {
                                                setShowForgetPasswordModal(true);
                                            }} className='text-primary cursor-pointer hover:underline'>
                                                {t("components.auth.forgetPassword")}
                                            </small>
                                        </div>
                                        <div className="field col md:col-12">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                name='password'
                                                label={t("components.auth.password")}
                                                variant='outlined'
                                                autoComplete='on'
                                                placeholder='*********'
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setPassword(e.target.value)
                                                }}
                                                value={values.password}
                                                type={passwordVisibility ? 'text' : 'password'}
                                                error={!!touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <EyeToggleButton
                                                            show={passwordVisibility}
                                                            click={togglePasswordVisibility}
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <Button
                                                type="submit"
                                                variant='contained'
                                                color='error'
                                                className='w-full'
                                                disabled={loader}
                                            >
                                                <span style={{ marginRight: '5px' }}>{t("login.title")}</span>
                                                {loader ? <i className="pi pi-spin pi-spinner" ></i> : <></>}
                                            </Button>
                                        </div>
                                        <p className="p-0 -m-2 line-height-3 w-full text-right text-sm">
                                            {t("login.dontHaveAccount")}
                                            {
                                                width <= 587 ? (<br />) : ''
                                            }
                                            <Trans i18nKey="login.clickHereLabel" >
                                                Si oui, <span className="text-primary cursor-pointer hover:underline" onClick={() => {
                                                    openCreateModal();
                                                }}>cliquez ici</span>
                                            </Trans>
                                        </p>
                                    </div>
                                </form>
                            )}
                        </Formik>

                        {
                            showForgetPasswordModal ? <ResetPasswordComponent
                                open={showForgetPasswordModal}
                                close={() => {
                                    setShowForgetPasswordModal(false);
                                }}
                                showSuccessMessage={({ title, message }) => {
                                    showSuccessMessage({
                                        title: title, message: message
                                    });
                                }}
                                showErrorMessage={({ title, message }) => {
                                    showErrorMessage({
                                        title: title, message: message
                                    });
                                }}
                            /> : ''
                        }
                    </ContentWrapper>
                    {
                        !showForgetPasswordModal ? <IconButton
                            sx={{
                                position: "absolute",
                                top: 3,
                                right: 3,
                            }}
                            onClick={onClose}
                        >
                            <i className='pi  pi-times'></i>
                        </IconButton> : ''
                    }
                </DialogContent>
            </Dialog >
        </>
    )
};

export default LoginModal;

const initialValues = {
    username: '',
    password: ''
};

const loginSchema = yup.object().shape({
    username: yup.string().required('Le nom d’utilisateur est requis'),
    password: yup.string().required('Le mot de passe est requis'),
})

const ContentWrapper = styled(Box)(({ theme }) => ({
    '& .carousel:hover': {
        cursor: 'pointer',
        '& .carousel__back-button': {
            opacity: 1,
            left: 10
        },
        '& .carousel__next-button': {
            opacity: 1,
            right: 10
        }
    },
    '& .carousel__next-button, & .carousel__back-button': {
        opacity: 0,
        boxShadow: 'none',
        transition: 'all 0.3s',
        background: 'transparent',
        color: theme.palette.primary.main,
        ':disabled': {
            color: theme.palette.grey[500]
        },
        ':hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    '& .carousel__back-button': {
        left: 0
    },
    '& .carousel__next-button': {
        right: 0
    }
})) // =====================================================
