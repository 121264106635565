import React, { useRef, useEffect, useState } from 'react'
import userApiService from '../../../services/userApiService';
import { Chart } from 'primereact/chart';
import commentApiService from '../../../services/commentApiService';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import StorageService from '../../../services/StorageService';

const UserPoint = () => {
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({})
    const [rating, setRating] = useState(0)
    const [showStat, setShowStat] = useState(false)
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        let user = StorageService.read("user");
        if (user && user?.id) {
            getUseById(user?.id);
            getRatingStatByUserId(user?.id);
        }

        ReactGA.send({
            hitType: "pageview",
            page: "profil_point_page"
        });
    }, [])

    const getUseById = async (userId) => {
        setLoading(true);
        let userResponse = await userApiService.getUserById(userId);
        if (userResponse && userResponse?.id) {
            setUser(userResponse);
        }
        setLoading(false);
    }

    const getRatingStatByUserId = async (userId) => {
        setLoading(true);
        let response = await commentApiService.getRatingStatById(userId);
        setShowStat(response.labels.length > 0)
        const data = {
            labels: response.labels,
            datasets: [
                {
                    data: response.data,
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
        setLoading(false);
    }




    return (
        <div className=''>
            <h3 className='text mt-0 pt-0'>{t('userPointPage.title')}</h3>
            <p className='line-height-3'>
                {t('userPointPage.description')}
            </p>
            {
                showStat ? (
                    <div className='w-full text-center flex justify-content-center align-items-center'>
                        <Chart type="pie" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                    </div>
                ) : ''
            }

            <h4>{t('userPointPage.summarize')} : {rating}</h4>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-VJ0Y9D0VXY"></script>
            <script src='https://soluchange.com/assets/scripts/google_analytic.js'> </script>
        </div>
    );
};

export default UserPoint;
