import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    TextField,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,

    Snackbar,
    Alert,
    useTheme,
    useMediaQuery,
    MobileStepper
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import authApiService from '../../services/authApiService';
import userApiService from '../../services/userApiService';
import StorageService from '../../services/StorageService';

const ResetPasswordComponent = (
    {
        onSubmit,
        open,
        close,
        showSuccessMessage,
        showErrorMessage,
    }
) => {
    const [activeStep, setActiveStep] = useState(0);
    const [username, setUsername] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { t, i18n } = useTranslation();
    const [logUsed, setLogUsed] = useState({})
    const [steps, setSteps] = useState(['Ancien mot de passe', 'Vérification', 'Nouveau mot de passe'])
    const [key, setKey] = useState(0); // State to force re-render

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const nextStep = async () => {
        if (activeStep == 0) {
            handleSendOtpCode();
        }
        if (activeStep == 1) {
            handleOtpCheck();
        }
        if (activeStep == 2) {
            handleChangePassword();
        }
    };


    useEffect(() => {
        const handleLanguageChange = () => {
            setKey(prevKey => prevKey + 1); // Increment key to force re-render
        };

        i18n.on('languageChanged', handleLanguageChange);

        setSteps(
            [
                t("ResetPasswordPage.steps.step1"),
                t("ResetPasswordPage.steps.step2"),
                t("ResetPasswordPage.steps.step3"),
            ]
        );

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    const handleOtpCheck = async () => {
        let response = await userApiService.checkUserCode(
            {
                username: username, otpCode
            }
        );

        if (response?.error) {
            showErrorMessage({
                title: t("errors.problemOccurred"), message: response.error
            });
        } else {
            if (response) {
                await generateAccessToken();
                handleNext();
                setError('');
            } else {
                setError(t("errors.problemOccurred"));
            }
        }
    }

    const generateAccessToken = async () => {
        await authApiService.generateAccessToken(
            {
                username: username, otpCode
            }
        );
    }

    const handleSendOtpCode = async () => {
        const response = await authApiService.sendOtpCode({
            username: username,
            action: "Reset",
            appSource: "SoluChange"
        });

        if (Object.keys(response).includes('error')) {
            setError(response.error);
        } else {
            if (response) {
                handleNext();
                setError('');
            } else {
                setError(t("errors.problemOccurred"));
            }
        }
    }


    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            setError(t("ResetPasswordPage.passwordDontCorrespond"));
            return;
        }

        const response = await authApiService.changePassword({
            username: username,
            newPassword: newPassword
        });

        if (Object.keys(response).includes('error')) {
            setError(t("errors.error_password_change"));
        } else {
            if (response) {
                setSuccess(t("ResetPasswordPage.successfulChange"));
                showSuccessMessage({
                    title: t("login.succeed.title"), message: t("login.succeed.message")
                });
                setActiveStep(0);
                setUsername('');
                setNewPassword('');
                setConfirmPassword('');
                close();
            } else {
                setError(t("errors.error_password_change"));
            }
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <TextField
                        fullWidth
                        label={t("components.auth.emailOrPhone")}
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        margin="normal"
                    />
                );
            case 1:
                return (
                    <TextField
                        fullWidth
                        label={t("components.auth.validate_otp.received_code_label")}
                        type="number"
                        value={otpCode}
                        onChange={(e) => setOtpCode(e.target.value)}
                        margin="normal"
                    />
                );
            case 2:
                return (
                    <>
                        <TextField
                            fullWidth
                            label={t("ResetPasswordPage.newPassword")}
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label={t("ResetPasswordPage.confirmNewPassword")}
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            margin="normal"
                        />
                    </>
                );
            default:
                return t("ResetPasswordPage.unknowStep");
        }
    };


    useEffect(() => {
        setSteps(
            [
                t("ResetPasswordPage.steps.step1"),
                t("ResetPasswordPage.steps.step2"),
                t("ResetPasswordPage.steps.step3"),
            ]
        );
        let user = StorageService.read("user");
        if (user && user?.id) {
            setLogUsed(user);
        } else {
        }

    }, [t])

    return (
        <>
            <Dialog
                open={open}
                maxWidth={false}
                sx={{
                    zIndex: 100
                }}
            >
                <DialogContent
                    sx={{
                        maxWidth: 555,
                        width: '100%'
                    }}
                >
                    <ContentWrapper>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <LockOutlinedIcon sx={{
                                m: 1,
                                bgcolor: 'primary.main',
                                color: 'white',
                                p: 1,
                                borderRadius: '50%',
                                fontSize: 60, // Icône agrandie,
                            }} />
                            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                                {t("ResetPasswordPage.title")}
                            </Typography>
                            <Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 3 }}>
                                {t("ResetPasswordPage.description")}
                            </Typography>
                            {isMobile ? (
                                <MobileStepper
                                    variant="text"
                                    steps={steps.length}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{ width: '100%', flexGrow: 1, mb: 4 }}
                                    nextButton={
                                        <Button size="small" onClick={nextStep} disabled={activeStep === steps.length - 1}>
                                            {t("global.next")}
                                            <KeyboardArrowRight />
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                            <KeyboardArrowLeft />
                                            {t("global.back")}
                                        </Button>
                                    }
                                />
                            ) : (
                                <Stepper activeStep={activeStep} sx={{ width: '100%', mb: 4 }}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            )}
                            <Box sx={{ width: '100%' }}>
                                {getStepContent(activeStep)}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} sx={{ mr: 1 }}>
                                            {t("global.back")}
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        onClick={nextStep}
                                    >
                                        {activeStep === steps.length - 1 ? t("global.finished") : t("global.next")}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Snackbar open={!!error || !!success} autoHideDuration={6000} onClose={() => { setError(''); setSuccess(''); }}>
                            <Alert severity={error ? "error" : "success"} sx={{ width: '100%' }}>
                                {error || success}
                            </Alert>
                        </Snackbar>
                    </ContentWrapper>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 3,
                            right: 3,
                        }}
                        onClick={() => {
                            console.log(close);

                            close();
                        }}
                    >
                        <i className='pi  pi-times'></i>
                    </IconButton>
                </DialogContent>
            </Dialog >
        </>
    );
}


export default ResetPasswordComponent;


const ContentWrapper = styled(Box)(({ theme }) => ({
    '& .carousel:hover': {
        cursor: 'pointer',
        '& .carousel__back-button': {
            opacity: 1,
            left: 10
        },
        '& .carousel__next-button': {
            opacity: 1,
            right: 10
        }
    },
    '& .carousel__next-button, & .carousel__back-button': {
        opacity: 0,
        boxShadow: 'none',
        transition: 'all 0.3s',
        background: 'transparent',
        color: theme.palette.primary.main,
        ':disabled': {
            color: theme.palette.grey[500]
        },
        ':hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    '& .carousel__back-button': {
        left: 0
    },
    '& .carousel__next-button': {
        right: 0
    }
})) // =====================================================
