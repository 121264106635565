import axios from 'axios'


import { url } from './constants'
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import StorageService from './StorageService';

const authUrl = url + 'api/auth'

axios.defaults.withCredentials = true

const login = async authObj => {
  let response = {}
  await axios
    .post(authUrl + '/login', authObj, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })

  return response
}

const checkPassword = async authObj => {
  let response = {}
  await axios
    .post(authUrl + '/check-password', authObj, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })

  return response
}

const changePassword = async authObj => {
  let response = {}
  await axios
    .post(authUrl + '/change-password', authObj, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })

  return response
}

const sendOtpCode = async authObj => {
  let response = {}
  await axios
    .post(authUrl + '/resend-otp', authObj, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })

  return response
}

const generateAccessToken = async authObj => {
  let response = {}
  await axios
    .post(authUrl + '/generate-access-token', authObj, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })

  return response
}

const register = async account => {
  let response = {}
  await axios
    .post(authUrl + '/register', account, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })

  return response
}

const deleteAccount = async account => {
  let response = {}
  await axios
    .delete(authUrl + '/archived-user/' + account,{}, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })

  return response
}

const logout = async userId => {
  await axios.get(authUrl + '/logout/' + userId);
  StorageService.deleteData("user");;
  StorageService.clear();
  sessionStorage.clear();
  Cookies.remove('accessToken');
  window.location.replace(window.location.origin);
  window.location.reload();
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  sendOtpCode,
  logout,
  generateAccessToken,
  deleteAccount,
  checkPassword,
  changePassword,
  register
}
