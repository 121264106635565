import axios from 'axios'
import qs from 'qs'
var CryptoJS = require("crypto-js");

const secretPass = "XkhZG4fW2t2WsecureLorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores molestiae laudantium fuga voluptatem eveniet deleniti iure, aperiam beatae ea doloribus provident atque ipsa consequuntur vitae. Eius voluptatum harum possimus temporibus?";


const encryptData = (value) => {
    return CryptoJS.AES.encrypt(
        JSON.stringify(value),
        secretPass
    ).toString();
};

const read = (key) => {
    let data = localStorage.getItem(key);

    if (data != null && data != undefined) {
        const bytes = CryptoJS.AES.decrypt(data, secretPass);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
}

const deleteData = (key) => {
    localStorage.removeItem(key)
}

const clear = () => {
    localStorage.clear();
}

const write = (key, value) => {
    const data = CryptoJS.AES.encrypt(
        JSON.stringify(value),
        secretPass
    ).toString();
    localStorage.setItem(key, data)
}

export default {
    read,
    write,
    deleteData,
    clear
}
