import axios from 'axios'

import { url } from './constants'

const announcementUrl = url + 'api/announcement/money/'

const createAnnoucement = async (announcementObj) => {
  let response = {}
  await axios
    .post(
      announcementUrl + 'create',
      announcementObj,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message }
    })
  return response
}

const searchAnnoucement = async (announcementObj) => {
  let response = {}
  await axios
    .post(
      announcementUrl + 'search',
      announcementObj,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message }
    })
  return response
}

const getAnnounceByUserId = async (userId) => {
  let response = {}
  await axios
    .get(
      announcementUrl + 'findByUser/' + userId,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message  }
    })
  return response
}

const getAnnounceById = async (id) => {
  let response = {}
  await axios
    .get(
      announcementUrl + '' + id,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message  }
    })
  return response
}

const archivedAnnounceById = async (id) => {
  let response = {}
  await axios
    .delete(
      announcementUrl + '' + id,
      {},
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message  }
    })
  return response
}

export default {
  createAnnoucement,
  archivedAnnounceById,
  getAnnounceByUserId,
  getAnnounceById,
  searchAnnoucement,
}
