import axios from 'axios'


import { url } from './constants'

const fileUrl = url + 'api/user/files'

const uploadFile = async image => {
  let response = {};

  const formData = new FormData()
  formData.append('file', image)
  await axios
    .post(fileUrl + '/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })

  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadFile
}
