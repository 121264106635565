import React, { useEffect, useRef, useState } from 'react'
import './ProfilMessage.css';
import Footer from '../../components/footer/Footer';
import messageApiService from '../../services/messageApiService';
import { Toast } from 'primereact/toast';
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import StorageService from '../../services/StorageService';

const ProfilMessage = () => {
    document.title = "Announce Message Page";

    const { t } = useTranslation();
    const [user, setUser] = useState({})
    const toast = useRef(null);
    const [messages, setMessages] = useState([]);
    const [messagesByOwnerOfAnnounce, setMessagesByOwnerOfAnnounce] = useState([]);
    const [messByInterestedUser, setMessByInterestedUser] = useState([]);
    const [selectedMessageType, setSelectedMessageType] = useState('');
    const [messagesFilters, setMessagesFilters] = useState([]);

    const getMessagesSummaryByOwnerOfAnnounce = async (userId) => {
        let response = await messageApiService.findByOwnerOfAnnounce(userId);
        if (response?.error) {
            showErrorMessage({
                title: t('errors.problemOccurred'), message: response?.error
            });
            return [];
        } else {
            setMessagesByOwnerOfAnnounce([...response]);
            return response;
        }
    }

    const getMessagesSummaryByInterestedUser = async (userId) => {
        let response = await messageApiService.findByInterestedUser(userId);
        if (response?.error) {
            showErrorMessage({
                title: t('errors.problemOccurred'), message: response?.error
            });
            return [];
        } else {
            setMessByInterestedUser([...response]);
            return response;
        }
    }

    const showErrorMessage = ({ title, message }) => {
        toast.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
    }

    const getDate = async (user) => {
        let summaryOfOwner = await getMessagesSummaryByOwnerOfAnnounce(user?.id);
        let totalMessageByOwner = summaryOfOwner.length;

        let summaryOfInterestUser = await getMessagesSummaryByInterestedUser(user?.id)
        let totalMessageByInterested = summaryOfInterestUser.length;

        setMessagesFilters([
            t("messagePage.totalMessages") + " (" + (totalMessageByOwner + totalMessageByInterested) + ")",
            t("messagePage.linkToMyAnnounce") + " (" + totalMessageByOwner + ")",
            t("messagePage.linkToOtherAnnounce") + " (" + totalMessageByInterested + ")"
        ])
        setSelectedMessageType(t("messagePage.totalMessages") + " (" + (totalMessageByOwner + totalMessageByInterested) + ")",)
        setMessages([...summaryOfOwner, ...summaryOfInterestUser]);
    }

    useEffect(() => {
        let user = StorageService.read("user");
        if (user && user?.id) {
            setUser(user);
            getDate(user);
        }
        ReactGA.send({
            hitType: "pageview",
            page: "announce_message_page"
        });
    }, [])

    const getUserWhoSendMessage = (message) => {
        if (message?.userWhoSendMessage == user.id) {
            return t("global.you")
        } else {
            if (message?.userWhoSendMessage == message?.user.id) {
                return message?.user?.lastname + ' ' + message?.user?.firstname
            } else {
                return message?.ownerOfAnnouncement?.lastname + ' ' + message?.ownerOfAnnouncement?.firstname
            }
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="profil-message-page page flex flex-column " style={{
                minHeight: '100vh'
            }}>
                <div className="profil-message-container flex-grow-1 p-4 md:p-6">
                    <div className='max-width-container mx-auto'>
                        <div className="banner mb-6">
                            <div className="banner-content grid p-4 md:p-6">
                                <div className='col-12 md:col-12'>
                                    <h1 className='text-left font-semibold line-height-3'>{t("messagePage.publicity")}</h1>
                                </div>
                                {/* <div className='col-12 md:col-2 my-auto'>
                                    <Button label="A lire ici" iconPos="right" severity="warning" raised icon="pi pi-arrow-right" />
                                </div> */}

                            </div>
                        </div>

                        <div className="messages">
                            <div className='mb-8 flex justify-content-between align-items-center flex-wrap'>
                                <div className='flex justify-content-between align-items-center'>
                                    <Dropdown value={selectedMessageType} onChange={(e) => {
                                        setSelectedMessageType(e.value);
                                        if (e.value.indexOf(t("messagePage.linkToOtherAnnounce")) > -1) {
                                            setMessages(messByInterestedUser);
                                        }
                                        if (e.value.indexOf(t("messagePage.linkToMyAnnounce")) > -1) {
                                            setMessages(messagesByOwnerOfAnnounce);
                                        }
                                        if (e.value.indexOf(t("messagePage.totalMessages")) > -1) {
                                            setMessages([...messagesByOwnerOfAnnounce, ...messByInterestedUser]);
                                        }
                                    }} options={messagesFilters}
                                        placeholder={"messagePage.messagesToShow"} className="w-full text-wrap mr-2" />
                                </div>
                            </div>

                            {messages.map((message, index) => (
                                <>
                                    <Link to={"/message-detail/announce/" + message?.announcement?.id + "/sender/" + message?.user?.id + "/owner/" + message?.ownerOfAnnouncement?.id} className='cursor-pointer hover:bg-blue-50 py-2 no-underline text-gray-900'>
                                        <div className="message  flex align-items-center">
                                            <div className='mr-6'>
                                                <img src={message?.user?.avatar ?? '/assets/images/001-man.svg'} className='w-4rem' alt="Aurore Finnys" />
                                            </div>
                                            <div className='flex-grow-1'>
                                                {
                                                    message?.announcement?.cityWhereHeSent?.name ? (
                                                        <h3 className='line-height-3 mt-0 mb-2'>{t('searchPage.searchFrom')} {message?.announcement?.cityWhereHeSent?.name} {t('global.to')} {message?.announcement?.cityWhereHeRetrieve?.name} ({message?.announcement?.amountToSend + " " + message?.announcement?.currencyTo + "    ->    " + message?.announcement?.amountToRetrieve + " " + message?.announcement?.currencyFrom})</h3>
                                                    ) : (
                                                        <h3 className='line-height-3 mt-0 mb-2'>{t("messagePage.exchangeCity", {
                                                            name: message?.announcement?.exchangeCity?.name
                                                        })} </h3>
                                                    )
                                                }
                                                <p className='m-0 line-height-3'>{message.message}</p>
                                                <div className='text-sm mt-2'>
                                                    <span className='font-medium'>{message?.ownerOfAnnouncement?.firstname} {', '}</span> <span>{message?.sendAt ? format(new Date(message.sendAt), 'MMM dd, yyyy') : ''}</span>
                                                </div>
                                                <div className='text-sm mt-2'>
                                                    <span>{t('annoucementPage.sendBy')} </span> <span className='font-medium'>{(message?.userWhoSendMessage == user.id ? 'Vous' : getUserWhoSendMessage(message))}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className='my-4'>
                                        <div className='line border-1 border-solid border-color-primary'></div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-VJ0Y9D0VXY"></script>
                <script src='https://soluchange.com/assets/scripts/google_analytic.js'> </script>
                <Footer />
            </div>
        </>

    );
}

export default ProfilMessage;
