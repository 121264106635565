import { Outlet, Link } from "react-router-dom";
import logo from '../../logo.svg';
import './Layout.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, createContext, useRef, useEffect, useState } from 'react'
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Steps } from 'primereact/steps';
import { FloatLabel } from 'primereact/floatlabel';
import { Formik } from 'formik'
import * as yup from 'yup'
import { useLocation } from 'react-router-dom';
import {
    Checkbox,
    Divider,
    Grid,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    ListItemText,
    Stack,
    ListSubheader,
    Typography,
    Box
} from '@mui/material'
import { Toast } from 'primereact/toast';
import InputLocationSearch from "../input-location-search/InputLocationSearch";
import announcementApiService from "../../services/announcementApiService";
import LoginModal from "../auth-modal/LoginModal";
import RegisterModal from "../auth-modal/RegisterModal";
import AnnouncementModal from "../announcement-modal/AnnouncementModal";
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import LayoutSidebar from "../sidebar/Sidebar";
import { Sidebar } from "primereact/sidebar";
import { useTranslation, Trans } from 'react-i18next';
import OtpVerificationModal from "../auth-modal/OtpVerificationCodeModal";
import PublishedOfferChoiceModal from "../../pages/home/components/AnnouncementCard/PublishedOfferChoice";
import moneyAnnouncementApiService from "../../services/moneyAnnouncementApiService";
import currencyAnnouncementApiService from "../../services/currencyAnnouncementApiService";
import AppShareModal from "../shared-modal/AppShareComponent";
import { useCookies } from "react-cookie";

import Cookies from 'js-cookie';
import useWindowSize from "./WindowResize";
import { HashLink } from "react-router-hash-link";
import { GA_MEASUREMENT_ID } from "../../services/constants";
import WebSocketComponent from "../websocket/WebSocketComponent";
import StorageService from "../../services/StorageService";

const Layout = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const { t, i18n } = useTranslation();
    const [loader, setLoader] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState("fr")
    const [languages, setLanguages] = useState([
        {
            name: "FR",
            code: "fr"
        },
        {
            name: "EN",
            code: "en"
        },
    ])
    const [visualisationToastTime, setVisualisationToastTime] = useState(3000)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false)
    const [showRegisterModal, setShowRegisterModal] = useState(false)
    const [showPublishedModalChoice, setShowPublishedModalChoice] = useState(false)
    const [didSaveAction, setDidSaveAction] = useState(false)
    const [showOtpVerificationModal, setShowOtpVerificationModal] = useState(false)
    const [showSearchResult, setShowSearchResult] = useState(false)
    const [userLogged, setUserLogged] = useState(false)
    const [hideHeader, setHideHeader] = useState(false)
    const [offerInProgress, setOfferInProgress] = useState({})
    const profilMenuRef = useRef(null);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [width, height] = useWindowSize();


    const [user, setUser] = useState({})
    const toast = useRef(null); 
    const navigate = useNavigate();


    let profilMenuItems = [
        {
            label: t("sidebar.menu.profil.view"),
            icon: 'pi pi-user',
            command: () => {
                navigate('/profil');
            }
        },
        {
            label: t("sidebar.menu.profil.update"),
            icon: 'pi pi-user-edit',
            command: () => {
                navigate('/profil/update');
            }
        },
        {
            label: t("sidebar.menu.logout"),
            icon: 'pi pi-sign-out',
            command: () => {
                setUser({})
                setUserLogged(false)
                StorageService.deleteData("user");;
                StorageService.clear();
                sessionStorage.clear();
                Cookies.remove('accessToken');
                navigate('/');
                window.location.reload();
            }
        }
    ];

    const location = useLocation();
    const [previousPath, setPreviousPath] = useState('');

    const loadHeader = () => {
        if (window.location.pathname.indexOf('/message-detail/announce') > -1) {
            setHideHeader(true)
        } else {
            setHideHeader(false)
        }

        let user = StorageService.read("user");
        if (user && user?.id) {
            setUser(user);
            setUserLogged(true);
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag('event', 'page_view', {
                page_path: location.pathname,
                page_title: location.pathname, // optional
                send_to: GA_MEASUREMENT_ID
            });
        }

        // Condition pour rafraîchir le layout
        if (shouldRefreshLayout(previousPath, location.pathname)) {
            refreshLayout();
        }

        // Mettre à jour l'URL précédente après chaque navigation
        setPreviousPath(location.pathname);
    }, [location]);

    const refreshLayout = () => {
        // Code pour réactualiser le layout (par exemple, re-fetch des données)
        setHideHeader(false);
    };

    const shouldRefreshLayout = (prevPath, currentPath) => {
        // Remplacez '/page-a' par l'URL que vous souhaitez vérifier
        return prevPath.indexOf('/message-detail/announce') > -1;
    };

    useEffect(() => {
        loadHeader();
    }, [])

    const handleCityToCallback = async (childData) => {
        let response = await announcementApiService.searchAnnoucement(childData);
        if (response?.error) {
            showErrorMessage({
                title: t("errors.problemOccurred"), message: response?.error
            });
        } else {
            sessionStorage.setItem('search-by-zone', 'true')
            sessionStorage.setItem('zone', JSON.stringify(childData))
            sessionStorage.setItem('zones-result', JSON.stringify(response))
            navigate('/search');
            window.location.reload();
        }
    };

    const saveMoneyAnnouncement = async (announce) => {
        let response = await moneyAnnouncementApiService.createAnnoucement(announce);

        if (response?.id) {
            sessionStorage.clear();
            showSuccessMessage({
                title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
            });
            navigate('/profil');
        } else {
            showErrorMessage({
                title: t('searchPage.announceNotSave'), message: response.error ?? t('searchPage.problemOccurredAtThisLevel')
            });
        }
    }

    const saveCurrencyAnnouncement = async (announce) => {
        let response = await currencyAnnouncementApiService.createAnnoucement(announce);

        if (response?.id) {
            sessionStorage.clear();
            showSuccessMessage({
                title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
            });
            navigate('/profil');
        } else {
            showErrorMessage({
                title: t('searchPage.announceNotSave'), message: response.error ?? t('searchPage.problemOccurredAtThisLevel')
            });
        }
    }

    const showSuccessMessage = ({ title, message }) => {
        toast.current.show({ severity: 'success', summary: title, detail: message, life: 5000 });
    }

    const showErrorMessage = ({ title, message }) => {
        toast.current.show({ severity: 'error', summary: title, detail: message, life: 5000 });
    }

    const showInfoMessage = ({ title, message }) => {
        toast.current.show({ severity: 'info', summary: title, detail: message, life: 5000 });
    }

    const showWarnMessage = ({ title, message }) => {
        toast.current.show({ severity: 'warn', summary: title, detail: message, life: 5000 });
    }

    const urlOfAnonymousUser = () => {
        return <>
            <ul className="list-none flex p-0 flex-wrap md:flex-nowrap align-items-center md:justify-content-between justify-content-center" style={{
                gap: '0.6rem '
            }}>
                <li className="link text-sm"></li>
                <li className="search md:w-11rem text-sm" style={{
                    minWidth: '8rem',
                    maxWidth: '11rem',
                    width: '-webkit-fill-available'
                }}>
                    <InputLocationSearch variant="standard" visualStyle={"header"} parentCallback={handleCityToCallback} title={t('layout.header.searchField')} className={"text-sm"} textSize={'text-xs'} />
                </li>
                <li className="search w-4rem">
                    <Select
                        className="text-xs"
                        MenuProps={{ autoFocus: false }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            setSelectedLanguage(e.target.value)
                            i18n.changeLanguage(e.target.value)
                        }
                        }
                        size='small'
                        labelId="search-select-label-for-currencyTo"
                        id="search-select-for-currencyTo"
                        value={selectedLanguage}
                        label="Options"
                    >
                        <ListSubheader>
                            <TextField
                                size="small"
                                className="text-xs"
                                // Autofocus on textfield
                                autoFocus
                                placeholder={t("global.changeLanguage")}
                                fullWidth
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                        {languages.map((option, i) => (
                            <MenuItem className="text-xs" key={i} value={option.code}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </li>
                <li className="link text-sm">
                    <HashLink smooth to='/#homeHeader' className="font-semibold text-sm text-primary">{t('layout.header.menu.home')}</HashLink>
                </li>
                {
                    userLogged ? '' : (
                        <li className="link text-sm w-10rem">
                            <HashLink smooth to='/#howItWork' className="font-semibold text-sm text-center text-secondary">{t('layout.header.menu.howItWork')}</HashLink>
                        </li>
                    )
                }
                <li className="link  text-sm w-10rem">
                    <span className="font-semibold text-sm text-primary text-primary text-center cursor-pointor" onClick={() => setShowAnnouncementModal(true)}>{t('layout.header.menu.placeAnOffer')}</span>
                </li>

                {
                    userLogged ? (
                        <>
                            <li className="link  text-sm">
                                <Link to="/profil-message" className="font-semibold text-sm text-primary text-primary text-center cursor-pointor">{t('layout.header.menu.message')}</Link>
                            </li>
                            <li className="link  text-sm w-6rem" style={{
                                maxWidth: '11rem'
                            }}>
                                <Menu model={profilMenuItems} popup ref={profilMenuRef} id="popup_menu_right" popupAlignment="right" />
                                <span link className="font-semibold text-sm text-primary text-primary text-center cursor-pointor" onClick={(event) => profilMenuRef.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup >
                                    <i className="pi pi-user mr-2"></i> {user?.firstname}
                                </span>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="link text-sm">
                                <span className="font-semibold text-sm text-primary text-primary" onClick={() => setShowLoginModal(true)}>{t('layout.header.menu.login')}</span>
                            </li>
                            <li className="link text-sm">
                                <Button size="small" className="bg-primary border-color-primary" label={t('layout.header.menu.signUp')} onClick={() => setShowRegisterModal(true)} raised />
                            </li>
                        </>
                    )
                }

                <li className="link text-sm">
                    <AppShareModal
                        size={'small'}
                        title={t('components.share_app.shareApp')}
                        // style={'text-sm'}
                        showSuccessMessage={({ title, message }) => {
                            showSuccessMessage({
                                title: title, message: message
                            });
                        }}
                        showErrorMessage={({ title, message }) => {
                            showErrorMessage({
                                title: title, message: message
                            });
                        }}
                    />
                </li>
            </ul>
        </>
    }

    return (
        <Box >
            {
                !hideHeader ? (
                    <header className="relative z-5 header w-full bg-white  px-4 md:px-6">
                        <nav className="nav hidden md:flex max-width-container mx-auto w-full flex-wrap md:flex-nowrap  align-items-center md:justify-content-between justify-content-center">
                            <div className="flex align-items-center">
                                {/* {
                                    width <= 1055 ? <li className="link mr-2 list-none">
                                        <Button raised icon="pi pi-bars" size="small" className="bg-primary border-color-primary" onClick={() => setVisibleLeft(true)} />
                                    </li> : ''
                                } */}
                                <HashLink smooth to='/#homeHeader' className="w-6 my-auto" style={{
                                    minWidth: '100px'
                                }}><img src={"/assets/images/soluchange_logo.png"} className="App-logo" alt="logo" /></HashLink>
                            </div>

                            {
                                width > 1055 ?
                                    urlOfAnonymousUser() : (
                                        <ul className="flex list-none gap-5 flex p-0  flex-wrap md:flex-nowrap align-items-center md:justify-content-between justify-content-center">
                                            <li className="search w-15rem">
                                                <InputLocationSearch variant="standard" visualStyle={"header"} parentCallback={handleCityToCallback} title={t('layout.header.searchField')} className={""} />
                                            </li>

                                            <li className="search w-4rem">
                                                <Select
                                                    className="text-xs"
                                                    MenuProps={{ autoFocus: false }}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => {
                                                        setSelectedLanguage(e.target.value)
                                                        i18n.changeLanguage(e.target.value)
                                                    }
                                                    }
                                                    size='small'
                                                    labelId="search-select-label-for-currencyTo"
                                                    id="search-select-for-currencyTo"
                                                    value={selectedLanguage}
                                                    label="Options"
                                                >
                                                    <ListSubheader>
                                                        <TextField
                                                            size="small"
                                                            className="text-xs"
                                                            // Autofocus on textfield
                                                            autoFocus
                                                            placeholder={t("global.changeLanguage")}
                                                            fullWidth
                                                            onKeyDown={(e) => {
                                                                if (e.key !== "Escape") {
                                                                    // Prevents autoselecting item while typing (default Select behaviour)
                                                                    e.stopPropagation();
                                                                }
                                                            }}
                                                        />
                                                    </ListSubheader>
                                                    {languages.map((option, i) => (
                                                        <MenuItem className="text-xs" key={i} value={option.code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </li>

                                            <li className="link">
                                                <Button raised icon="pi pi-bars" size="small" className="bg-primary border-color-primary" onClick={() => setVisibleLeft(true)} />
                                            </li>
                                        </ul>
                                    )
                            }
                        </nav>

                        <nav className="flex py-2 md:hidden nav max-width-container mx-auto w-full flex-nowrap align-items-center justify-content-between">
                            <div className="flex align-items-center">
                                <li className="link mr-2 list-none">
                                    <Button raised icon="pi pi-bars" size="small" className="bg-primary border-color-primary" onClick={() => setVisibleLeft(true)} />
                                </li>
                                <HashLink smooth to='/#homeHeader' className="w-7 my-auto flex align-items-center"><img src={"/assets/images/soluchange_logo.png"} className="App-logo" alt="logo" /></HashLink>
                            </div>


                            <div className="flex gap-2">

                                {
                                    userLogged ? (
                                        <>
                                            <Menu model={profilMenuItems} popup ref={profilMenuRef} id="popup_menu_left" popupAlignment="right" />
                                            <Button icon="fa-solid fa-user-gear" text raised severity="danger" aria-label={t('layout.header.menu.myProfil')} onClick={(event) => profilMenuRef.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
                                        </>
                                    ) : (
                                        <Button icon="fa-solid fa-user-plus" text raised severity="danger" aria-label={t('layout.header.menu.signUp')} onClick={() => setShowRegisterModal(true)} />
                                    )
                                }
                                {/* <Button icon="fa-solid fa-right-to-bracket" text raised severity="danger" aria-label={t('layout.header.menu.signUp')} onClick={() => setShowRegisterModal(true)} /> */}
                                <AppShareModal
                                    size={'small'}
                                    title={''}
                                    // style={'text-sm'}
                                    showSuccessMessage={({ title, message }) => {
                                        showSuccessMessage({
                                            title: title, message: message
                                        });
                                    }}
                                    showErrorMessage={({ title, message }) => {
                                        showErrorMessage({
                                            title: title, message: message
                                        });
                                    }}
                                />
                            </div>
                        </nav>
                        <nav className="flex pt-2 pb-3 gap-3 md:hidden nav max-width-container mx-auto w-full flex-nowrap align-items-center justify-content-between">
                            <li className="search list-none" style={{
                                minWidth: '8rem',
                                // maxWidth: '16rem',
                                width: '-webkit-fill-available'
                            }}>
                                <InputLocationSearch variant="standard" visualStyle={"header"} parentCallback={handleCityToCallback} title={t('layout.header.searchField')} className={""} />
                            </li>

                            <li className="search w-5rem list-none">
                                <Select
                                    className="text-sm"
                                    MenuProps={{ autoFocus: false }}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        setSelectedLanguage(e.target.value)
                                        i18n.changeLanguage(e.target.value)
                                    }
                                    }
                                    size='small'
                                    labelId="search-select-label-for-currencyTo"
                                    id="search-select-for-currencyTo"
                                    value={selectedLanguage}
                                    label="Options"
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            className="text-sm"
                                            // Autofocus on textfield
                                            autoFocus
                                            placeholder={t("global.changeLanguage")}
                                            fullWidth
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    // Prevents autoselecting item while typing (default Select behaviour)
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {languages.map((option, i) => (
                                        <MenuItem className="text-sm" key={i} value={option.code}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </li>
                        </nav>
                    </header>
                ) : ''
            }

            <Toast ref={toast} />


            <Sidebar baseZIndex={80} visible={visibleLeft} position="left" onHide={() => setVisibleLeft(false)}>
                {/* {
                    userLogged ? urlOfLoggedUser() : urlOfAnonymousUser()
                } */}

                <LayoutSidebar
                    languages={languages}
                    onCloseSidebar={() => setVisibleLeft(false)}
                    language={selectedLanguage}
                    setShowLoginModal={(value) => { setShowLoginModal(value) }}
                    setShowRegisterModal={(value) => { setShowRegisterModal(value) }}
                    changeLanguage={
                        (value) => {
                            i18n.changeLanguage(value); setSelectedLanguage(value)
                        }
                    }
                    openAnnounceModal={
                        () => {
                            setVisibleLeft(false);
                            setShowAnnouncementModal(true);
                        }
                    }
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                />
            </Sidebar>

            {
                showLoginModal ? <LoginModal
                    showOtpVerification={() => {
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setShowOtpVerificationModal(true);
                    }}
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    visualisationToastTime={visualisationToastTime}
                    onSubmit={(logUser) => {
                        setUser(logUser);
                        setUserLogged(true);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setShowOtpVerificationModal(false);
                        if (Object.keys(offerInProgress).includes("rate")) {
                            if (didSaveAction) {
                                offerInProgress.userId = logUser?.id;
                                if (Object.keys(offerInProgress).includes("cityWhereHeRetrieve")) {
                                    saveMoneyAnnouncement(offerInProgress);
                                } else {
                                    saveCurrencyAnnouncement(offerInProgress);
                                }
                            } else {
                                setShowPublishedModalChoice(true);
                            }
                        }
                    }} open={showLoginModal} openCreateModal={() => { setShowLoginModal(false); setShowRegisterModal(true); }} onClose={() => setShowLoginModal(false)} /> : ''
            }

            {
                showAnnouncementModal ? <AnnouncementModal
                    showValidityInput={true}
                    hideMenuTabOffer={false}
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    opentLoginModal={(offer) => {
                        setOfferInProgress(offer);
                        setVisualisationToastTime(0);
                        setShowAnnouncementModal(false);
                        setShowPublishedModalChoice(false);
                        setShowRegisterModal(false);
                        setShowLoginModal(true);
                    }}
                    openPublishedModalChoice={(offer) => {
                        setOfferInProgress(offer);
                        setVisualisationToastTime(0);
                        setShowAnnouncementModal(false);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setShowPublishedModalChoice(true);
                    }}
                    open={showAnnouncementModal}
                    onClose={() => setShowAnnouncementModal(false)}
                /> : ''
            }

            {
                showRegisterModal ? <RegisterModal
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    visualisationToastTime={visualisationToastTime}
                    open={showRegisterModal} onSubmit={(logUser) => {
                        // setUser(logUser);
                        // setUserLogged(true);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setShowOtpVerificationModal(true);
                    }}
                    openLoginModal={() => { setShowRegisterModal(false); setShowLoginModal(true); }} onClose={() => setShowRegisterModal(false)} /> : ''
            }

            {
                showOtpVerificationModal ? <OtpVerificationModal
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    visualisationToastTime={visualisationToastTime}
                    open={showOtpVerificationModal} onSubmit={(logUser) => {
                        setUser(logUser);
                        setUserLogged(true);
                        setShowOtpVerificationModal(false);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        if (Object.keys(offerInProgress).includes("rate")) {
                            if (didSaveAction) {
                                offerInProgress.userId = logUser?.id;
                                if (Object.keys(offerInProgress).includes("cityWhereHeRetrieve")) {
                                    saveMoneyAnnouncement(offerInProgress);
                                } else {
                                    saveCurrencyAnnouncement(offerInProgress);
                                }
                            } else {
                                setShowPublishedModalChoice(true);
                            }
                        }
                    }} onClose={() => setShowOtpVerificationModal(false)} /> : ''
            }

            {
                showPublishedModalChoice ? <PublishedOfferChoiceModal
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    openLoginModal={(offer) => {
                        setVisualisationToastTime(0);
                        setOfferInProgress(offer);
                        setDidSaveAction(true);
                        setShowAnnouncementModal(false);
                        setShowPublishedModalChoice(false);
                        setShowRegisterModal(false);
                        setShowLoginModal(true);
                    }}
                    offer={offerInProgress} open={showPublishedModalChoice} onSubmit={() => {

                    }} onClose={() => setShowPublishedModalChoice(false)} /> : ''
            }

            <WebSocketComponent
                showInfoMessage={({ title, message }) => {
                    showInfoMessage({
                        title: title, message: message
                    });
                }}
                showWarnMessage={({ title, message }) => {
                    showWarnMessage({
                        title: title, message: message
                    });
                }}
                showSuccessMessage={({ title, message }) => {
                    showSuccessMessage({
                        title: title, message: message
                    });
                }}
                showErrorMessage={({ title, message }) => {
                    showErrorMessage({
                        title: title, message: message
                    });
                }}
            />
            <Outlet context={{ showSuccessMessage, showErrorMessage, showInfoMessage, showWarnMessage }} />
        </Box>
    )
};

export default Layout;
