import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    TextField,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Paper,
    Container,
    Snackbar,
    Alert,
    useTheme,
    useMediaQuery,
    MobileStepper
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTranslation } from 'react-i18next';
import authApiService from '../../../services/authApiService';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import StorageService from '../../../services/StorageService';

const UpdatePasswordComponent = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { t, i18n } = useTranslation();
    const [logUsed, setLogUsed] = useState({})
    const [steps, setSteps] = useState(['Ancien mot de passe', 'Vérification', 'Nouveau mot de passe'])
    const [key, setKey] = useState(0); // State to force re-render

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        const handleLanguageChange = () => {
            setKey(prevKey => prevKey + 1); // Increment key to force re-render
        };

        i18n.on('languageChanged', handleLanguageChange);

        setSteps(
            [
                t("verificationPasswordPage.steps.step1"),
                t("verificationPasswordPage.steps.step2"),
                t("verificationPasswordPage.steps.step3"),
            ]
        );

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);


    const handleCheckPassword = async () => {
        const response = await authApiService.checkPassword({
            username: logUsed?.email ?? logUsed?.phone,
            password: oldPassword
        });

        if (Object.keys(response).includes('error')) {
            setError(t("verificationPasswordPage.verification_fail"));
        } else {
            if (response) {
                handleNext();
                setError('');
            } else {
                setError(t("verificationPasswordPage.password_incorrect"));
            }
        }
    }

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            setError(t("verificationPasswordPage.passwordDontCorrespond"));
            return;
        }

        const response = await authApiService.changePassword({
            username: logUsed?.email ?? logUsed?.phone,
            newPassword: newPassword
        });

        if (Object.keys(response).includes('error')) {
            setError(t("errors.error_password_change"));
        } else {
            if (response) {
                setSuccess(t("verificationPasswordPage.successfulChange"));
                setActiveStep(0);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                setError(t("errors.error_password_change"));
            }
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <TextField
                        fullWidth
                        label={t("verificationPasswordPage.lastPassword")}
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        margin="normal"
                    />
                );
            case 1:
                return (
                    <Typography>
                        {t("verificationPasswordPage.verifiedLastPassword")}
                    </Typography>
                );
            case 2:
                return (
                    <>
                        <TextField
                            fullWidth
                            label={t("verificationPasswordPage.newPassword")}
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label={t("verificationPasswordPage.confirmNewPassword")}
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            margin="normal"
                        />
                    </>
                );
            default:
                return t("verificationPasswordPage.unknowStep");
        }
    };

    useEffect(() => {
        setSteps(
            [
                t("verificationPasswordPage.steps.step1"),
                t("verificationPasswordPage.steps.step2"),
                t("verificationPasswordPage.steps.step3"),
            ]
        );
        let user = StorageService.read("user");
        if (user && user?.id) {
            setLogUsed(user);
        } else {
        }

    }, [t])


    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={3} sx={{ mt: 0, p: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <LockOutlinedIcon sx={{
                        m: 1,
                        bgcolor: 'primary.main',
                        color: 'white',
                        p: 1,
                        borderRadius: '50%',
                        fontSize: 60, // Icône agrandie,
                    }} />
                    <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                        {t("verificationPasswordPage.title")}
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 3 }}>
                        {t("verificationPasswordPage.description")}
                    </Typography>
                    {isMobile ? (
                        <MobileStepper
                            variant="text"
                            steps={steps.length}
                            position="static"
                            activeStep={activeStep}
                            sx={{ width: '100%', flexGrow: 1, mb: 4 }}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={activeStep === steps.length - 1}>
                                    {t("global.next")}
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    <KeyboardArrowLeft />
                                    {t("global.back")}
                                </Button>
                            }
                        />
                    ) : (
                        <Stepper activeStep={activeStep} sx={{ width: '100%', mb: 4 }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )}
                    <Box sx={{ width: '100%' }}>
                        {getStepContent(activeStep)}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} sx={{ mr: 1 }}>
                                    {t("global.back")}
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                onClick={
                                    activeStep === steps.length - 1
                                        ? handleChangePassword
                                        : activeStep === 1
                                            ? handleCheckPassword
                                            : handleNext
                                }
                            >
                                {activeStep === steps.length - 1 ? t("global.finished") : t("global.next")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <Snackbar open={!!error || !!success} autoHideDuration={6000} onClose={() => { setError(''); setSuccess(''); }}>
                <Alert severity={error ? "error" : "success"} sx={{ width: '100%' }}>
                    {error || success}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UpdatePasswordComponent;