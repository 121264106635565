import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Avatar } from 'primereact/avatar';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import commentApiService from '../../services/commentApiService';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useTranslation, Trans } from 'react-i18next';
import { ListSubheader, MenuItem, Select, TextField } from '@mui/material';
import AppShareModal from '../shared-modal/AppShareComponent';
import Cookies from 'js-cookie';
import { HashLink } from 'react-router-hash-link';
import StorageService from '../../services/StorageService';

const LayoutSidebar = ({
    className,
    language,
    onCloseSidebar,
    showSuccessMessage,
    showErrorMessage,
    setShowRegisterModal,
    openAnnounceModal,
    setShowLoginModal,
    changeLanguage,
    languages
}) => {
    const [selectedLanguage, setSelectedLanguage] = useState(language ?? "fr")

    const profilMenuRef = useRef(null);
    const [logUser, setLogUser] = useState({
        id: "",
    });
    const [rating, setRating] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();

    let profilMenuItems = [
        {
            label: t("sidebar.menu.profil.view"),
            icon: 'pi pi-user',
            command: () => {
                navigate('/profil');
                onCloseSidebar();
            }
        },
        {
            label: t("sidebar.menu.profil.update"),
            icon: 'pi pi-user-edit',
            command: () => {
                navigate('/profil/update');
                onCloseSidebar();
            }
        },
        {
            label: t("sidebar.menu.logout"),
            icon: 'pi pi-sign-out',
            command: () => {
                setLogUser({})
                StorageService.deleteData("user");;
                StorageService.clear();
                sessionStorage.clear();
                Cookies.remove('accessToken');
                navigate('/');
                onCloseSidebar();
                window.location.reload();
            }
        }
    ];

    useEffect(() => {
        let user = StorageService.read("user");
        if (user && user?.id) {
            setLogUser(user);
            getUserRating(user);
        }
    }, [])

    const getUserRating = async (userId) => {
        let response = await commentApiService.getRatingById(userId);
        setRating(response);
    }


    return (
        <div className={className + ' sidebar'}>
            {
                logUser && logUser?.id ? (
                    <div className='mb-4 flex justify-content-center flex-column align-items-center text-center'>
                        <Avatar image={logUser?.avatar ? logUser?.avatar : "/assets/images/001-man.svg"} className="w-6rem h-6rem mb-4" size="xlarge" shape="circle" />
                        {/* <Rating value={rating} readOnly cancel={false} /> */}
                    </div>
                ) : ''
            }
            <ul className='list-none p-0'>
                <li className='text py-2'>
                    <div className='flex align-items-center justify-content-between'>
                        <HashLink smooth to='/#homeHeader' onClick={() => { onCloseSidebar(); }} className={"text-lg text no-underline border-none link " + (
                            window.location.pathname == '/' > -1 ? 'active' : ''
                        )}>{t('sidebar.menu.home')}</HashLink>

                        <div className='w-5rem'>
                            <Select
                                // Disables auto focus on MenuItems and allows TextField to be in focus
                                MenuProps={{ autoFocus: false }}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size='small'
                                labelId="search-select-label-for-currencyTo"
                                id="search-select-for-currencyTo"
                                value={selectedLanguage}
                                label="Options"
                                onChange={(e) => {
                                    if (changeLanguage) {
                                        setSelectedLanguage(e.target.value)
                                        changeLanguage(e.target.value)
                                        onCloseSidebar();
                                    }
                                }
                                }
                            >
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        // Autofocus on textfield
                                        autoFocus
                                        placeholder={t("global.changeLanguage")}
                                        fullWidth
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") {
                                                // Prevents autoselecting item while typing (default Select behaviour)
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                </ListSubheader>
                                {languages.map((option, i) => (
                                    <MenuItem key={i} value={option.code}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                </li>
                <li className='text'>
                    <span className="text-lg text no-underline border-none link" onClick={() => {
                        if (openAnnounceModal) {
                            openAnnounceModal();
                            onCloseSidebar();
                        }
                    }
                    }>{t('sidebar.menu.placeAnOffer')}</span>
                </li>
                <li className='text'>
                    {
                        logUser && logUser?.id ? (
                            <Link to="/profil-message" onClick={() => { onCloseSidebar(); }} className={"text-lg text no-underline border-none link " + (
                                window.location.pathname.indexOf('/profil-message') > -1 ? 'active' : ''
                            )}>{t('sidebar.menu.message')}</Link>
                        ) : (
                            <HashLink smooth to='/#howItWork' onClick={() => { onCloseSidebar(); }} className={"text-lg text no-underline border-none link text-secondary"}>{t('layout.header.menu.howItWork')} </HashLink>
                        )
                    }
                </li>

                {
                    logUser && logUser?.id ? (
                        <li className='text'>
                            <Link to="/profil" onClick={() => { onCloseSidebar(); }} className={"text-lg text no-underline border-none link " + (
                                window.location.pathname.indexOf('/profil/announces') > -1 ? 'active' : ''
                            )}>{t('sidebar.menu.myAnnounces')}</Link>
                        </li>) : ""
                }

                {
                    logUser && logUser?.id ? "" : (
                        <li className='text p-2'>
                            <div className='flex gap-3 align-items-center justify-content-between'>
                                <Link to="/" className={"text-lg text no-underline border-none link"} onClick={() => { setShowLoginModal(true); onCloseSidebar(); }}>{t('layout.header.menu.login')}</Link>

                                <Button size="small" className="bg-primary border-color-primary w-full" label={t('layout.header.menu.signUp')} onClick={() => { setShowRegisterModal(true); onCloseSidebar(); }} raised />
                            </div>
                        </li>
                    )
                }

                {
                    logUser && logUser?.id ? (
                        <li className='text <-full' onClick={(event) => profilMenuRef.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup>
                            <Link className="text-lg text no-underline border-none link w-full"> <i className="pi pi-user mr-2"></i> {logUser?.firstname}</Link>
                        </li>) : ""
                }
                <Menu model={profilMenuItems} popup ref={profilMenuRef} id="popup_menu_left" popupAlignment="right" />

                {
                    logUser && logUser?.id ? '' :
                        (<li className="my-4 ">
                            <AppShareModal
                                size={'small'}
                                style={'w-full'}
                                title={t('components.share_app.shareApp')}
                                // style={'text-sm'}
                                showSuccessMessage={({ title, message }) => {
                                    showSuccessMessage({
                                        title: title, message: message
                                    });
                                }}
                                showErrorMessage={({ title, message }) => {
                                    showErrorMessage({
                                        title: title, message: message
                                    });
                                }}
                            />
                        </li>
                        )
                }

            </ul>
        </div>
    );
}

export default LayoutSidebar;
